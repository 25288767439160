import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./screens/sexual-health-test/hero"
import UnderHero from "./screens/sexual-health-test/under-hero"
import Revolutionized from "./screens/sexual-health-test/revolutionized"
import SeriousTargets from "./screens/sexual-health-test/serious-targets"
import HowToUse from "./screens/sexual-health-test/how-to-use"
import ProductDetails from "./screens/sexual-health-test/product-details"
import Cta from "./screens/sexual-health-test/cta"
import DoctorTestimonials from "./screens/sexual-health-test/doctor-testimonials"
import StiSpecifications from "./screens/sexual-health-test/sti-specifications"
import Downloads from "./screens/sexual-health-test/downloads"

const SexualHealthTest = () => {
  return (
    <React.Fragment>
      <Hero />
      <UnderHero />
      <Revolutionized />
      <SeriousTargets />
      <DoctorTestimonials />
      <HowToUse />
      <ProductDetails />
      <StiSpecifications />
      <Downloads />
      <Cta />
    </React.Fragment>
  )
}
export default SexualHealthTest

export const Head = () => (
  <Seo
	  title="Sexual Health Test — POC rapid PCR device"
	  description="Visby Medical's PCR Sexual Health Test detects chlamydia, gonorrhea, and trichomoniasis in under 30 minutes."
	  image="/meta/sexual-health-test.jpg"
/>
)
