import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ButtonLink } from "components/buttons"

// Icons
import Check from "assets/icons/checkmark.svg"

const StyledContainer = styled(Container)`
  max-width: 1200px;
  width: 95%;
  padding: 0;
  margin: 0 auto;
`

const StyledUnderHero = styled.section`
  padding: 20px 0 0 0;
  text-align: center;

  ${breakpoint.medium`
    padding: 60px 0 0 0;
  `}

	h1 {
		text-align: center;
		font-size: 32px;
		padding: 10px;

      ${breakpoint.medium`
		width: 100%;
		font-size: 42px;
		padding: 0;
		margin-bottom: 10px;
      `}
	 }


	.point {
		color: ${colors.purpleDark};
	 	text-align: center;
		display: inline-grid;
		font-weight: 700;
		line-height: 1.2em;
		margin-bottom: 4.8px!important;
		padding: 0!important;
		font-size: 20px;
	
		${breakpoint.medium`
			display: block;
			text-align: left;
		`}
		
		&::before {
            content: url(${Check});
            
			${breakpoint.small`
				margin-right: 6px;
			`}
		}
	}

	.detail {
		text-align: center;
		font-size: 16px;
		line-height: 1.4em;
		padding: 0;
		margin-bottom: 2rem;

		${breakpoint.medium`
			padding: 0 0.4rem 0 2rem;
			text-align: left;
		`}
	}

	.row {
		display: flex;
		margin-top: 60px;
		flex-direction: column;
	}
	
	.column {
		flex: 0;
		align-self: center;
		min-width: 26%;

		&.first {
			margin-top: 0;
			min-width: 27%;
		}

		&.middle {
			flex: 1;
			min-width: 60%;
		}
				
		p {
			padding: 0 1rem;
			margin: 0 auto 1rem !important;
		}

		.forWomen {
			line-height: 1.2em;
			padding-bottom: 0rem;
			padding-top: 1rem;
			font-style: italic;

			${breakpoint.medium`
				line-height: 1.3em;
				padding: 1rem 0 1rem!important;
			`}
		}

	}
		
	.centerImage {
		width: 100%;
		padding: 15px 30px;
		max-width: 400px;
		display: block;
		margin: 15px auto 0;
	  
		${breakpoint.small`
			margin: 20px auto 5px;
			max-width: 550px;
			padding: 0;
		`}
	}

	.columnBullets {
		display: grid;
		grid-auto-flow: row;
		width: 100%;
		max-width: 1100px;
		margin: 35px auto 0;

		${breakpoint.medium`
			grid-auto-flow: column;
		`}

	}
	
	.publicationInfo {
		margin: 50px 0 100px;
	}

	.publication {
		font-family: serif;
		font-size: 33px;
		margin: 80px auto 10px;
	}
	
	.quote {
		
      &::before {
        content: "“";
      }

		&::after {
        content: "” ";
      }

	}

	.quoteText {
		padding: 0px;
		margin: 0 auto;

	  ${breakpoint.xs`
		width: 77%;
	  `}

      a {
   		color: ${colors.orange};   
      }
      
      a:hover {
   		color: ${colors.purpleDark};   
      }
	}

	${ButtonLink} {
        width: auto;
		margin-top: 15px;
    }
  
`

const UnderHero = props => {
	return (
		<StyledUnderHero>
			<StyledContainer>
				<div className="row">
					<div className="column first" data-aos="fade-up">
						<h1>Test, talk and treat - in the same visit.</h1>
					</div>
					<div className="column middle" data-aos="fade-up" data-aos-delay="100">
						<StaticImage
							src="../../../assets/images/sexual-health-test/device-front.png"
							width={800}
							alt="Visby Medical Sexual Health Test"
							className="centerImage"
							formats={["webp"]}
						/>
						<p className="forWomen" data-aos="fade-up" data-aos-delay="100">For self-collected vaginal swabs</p>
					</div>
					<div className="columnBullets">
						<div data-aos="fade-up">
							<p className="point">Accurate</p>
							<p className="detail">Delivers ~97% accuracy. See our instructions for use.</p>
						</div>
						<div data-aos="fade-up" data-aos-delay="200">
							<p className="point">Fast</p>
							<p className="detail">Results in under 30 minutes, while the patient is present.</p>
						</div>
						<div data-aos="fade-up" data-aos-delay="400">
							<p className="point">CLIA-waived</p>
							<p className="detail">Easy to use at the point-of-care; no extra instrument or maintenance needed.</p>
						</div>
						<div data-aos="fade-up" data-aos-delay="600">
							<p className="point">Antibiotic&nbsp;stewardship</p>
							<p className="detail">Avoid unnecessary antibiotics.</p>
						</div>
					</div>
				</div>
				<div className="column publicationInfo" data-aos="fade-up" data-aos-delay="200">
				<div className="publication">
						<StaticImage
							src="../../../assets/images/sexual-health-test/lancet.png"
							width={200}
							formats={["webp"]}
							alt="The Lancet"
							className="logo"
						/>
					</div>
					<p className="quoteText"><span className="quote">The device is potentially the new gold standard for point-of-care tests
						for infectious diseases such as sexually transmitted infections (STIs) and influenza and coronavirus infections,
						in which rapid turnaround is key.</span>
						<Link
							to="/news/lancet-publishes-paper-on-the-visby-medical-point-of-care-pcr-technology/"
							aria-label="Read the Lancet paper regarding PCR technology"
						>
							read&nbsp;more
						</Link></p>
						<ButtonLink backgroundcolor="primary" to="/how-to-buy/" data-aos="fade-up" data-aos-delay="300">Order Now</ButtonLink>
				</div>
			</StyledContainer>
		</StyledUnderHero>
	)
}

export default UnderHero
