import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"


const StyledContainer = styled(Container)`
  max-width: 1100px;
  width: 100%;
  margin: 60px auto 0;
  padding: 0px;
`

const StyledSeriousTargets = styled.section`
  position: relative;
  border-bottom-width: 0;
  text-align: center;
  padding: 40px 20px 50px;
  
  ${breakpoint.small`
	text-align: left;
  `}

	${breakpoint.medium`
		padding: 60px;
	`}

  .heading {
	margin: 0px 20px;
	text-align: center;
	padding-bottom: 20px;
	
    ${breakpoint.medium`
      margin: 0px;
      padding-bottom: 60px;
    `}
    
    h2 { 
    	padding-bottom: 40px;
    }

    h3 { 
    	padding: 25px 0 10px;
		font-size: 22px;
		margin-bottom: 3px;
		
		${breakpoint.medium`
			font-size: 24px;
		`}
    }

	.content {
		  display: grid;
		  grid-auto-flow: row;
		  margin: 40px auto 0;
		  grid-gap: 0;
		  row-gap: 2em;

		  ${breakpoint.small`
		  grid-auto-flow: column;
		  grid-auto-columns: 1fr;
		  grid-gap: 2rem;
		  `}

		a {
			color: ${colors.orange};
		}
		
		a:hover {
			color: ${colors.purpleDark};
		}
		
		 }
		  
	.targetImg {
		width: 200px;	
		margin: 0 auto;
	}
	
}

	.sub {
		font-style: italic;
		margin-top: 0.6em;
		line-height: 1.2em;
		font-size: x-small;
		color: ${colors.grayDark};
		padding-inline: 30px;

		${breakpoint.medium`
		  margin-top: 1em;
		  font-size: small;
		  line-height: 1rem;
		`}
	}
   
`

const SeriousTargets = () => {

	return (
    <StyledSeriousTargets>
      <StyledContainer>
        <div className="heading">
			<h2 data-aos="fade-up" data-aos-once="false">Three critical targets</h2>
			<p data-aos="fade-up" data-aos-once="false">Our sexual health test targets three pathogens that, 
			if left untreated, can cause permanent damage to a woman’s reproductive system, including infertility, or potentially causing fatal 
			ectopic pregnancy (pregnancy that occurs outside the womb). Fast PCR can help with early detection of these serious infections. </p>
				<div className="content">
					<div className="target" data-aos-delay="100" data-aos="fade-up" data-aos-once="false">
						<StaticImage
							src="../../../assets/images/sexual-health-test/targets/target1.png"
							width={200}
							alt="Chlamydia"
							className="targetImg"
						/>
						<h3>Chlamydia</h3>
						<p>The most common reportable bacterial STI in the US<sup>2</sup>. Often without symptoms but is associated with significant 
						healthcare costs, affects people of all ages, and is most common in young women.</p>
						<p className="sub">2. National overview – sexually transmitted 
						disease surveillance, 2019. <a
                			href="https://www.cdc.gov/std/statistics/2019/overview.htm#chlamydia"
               				target="_blank"
               				rel="noreferrer noopener"
							style={{display: "inline-block"}}
						 >Centers for Disease Control and Prevention (CDC) website</a></p>
					</div>
					<div className="target" data-aos-delay="200" data-aos="fade-up" data-aos-once="false">
						<StaticImage
							src="../../../assets/images/sexual-health-test/targets/target3.png"
							width={200}
							alt="Gonorrhea"
							className="targetImg"
						/>
						<h3>Gonorrhea</h3>
						<p>The second most common reportable condition in the US, this bacterial STI<sup>3</sup>, 
						if untreated, may cause infertility. Babies of infected mothers can be infected during childbirth.</p>
						<p className="sub">3. National overview – sexually transmitted 
						disease surveillance, 2019. <a
                			href="https://www.cdc.gov/std/statistics/2019/overview.htm#Gonorrhea"
               				target="_blank"
               				rel="noreferrer noopener"
							style={{display: "inline-block"}}
						 >Centers for Disease Control and Prevention (CDC) website</a></p>
					</div>
					<div className="target" data-aos-delay="300" data-aos="fade-up" data-aos-once="false">
						<StaticImage
							src="../../../assets/images/sexual-health-test/targets/target2.png"
							width={200}
							alt="Trichomoniasis"
							className="targetImg"
						/>
						<h3>Trichomoniasis</h3>
						<p>Caused by a protozoan parasite, Trichomoniasis is the third most common STI in the US<sup>4</sup>, yet most do not realize they are infected. 
						Trichomoniasis increases the risk of contracting other STIs, including 2-3 times greater risk of HIV acquisition<sup>5</sup>.</p>
						<p className="sub">4. Microb Cell. 2016;3:404-418<br />5. J Infect Dis. 2007;195:698-702</p>
					</div>
				</div>
			</div>
      </StyledContainer>
    </StyledSeriousTargets>
  )
}

export default SeriousTargets