import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Glide from "@glidejs/glide"

// Stylesheets
import "@glidejs/glide/dist/css/glide.core.min.css"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import { Button } from "components/buttons"

//Icons
import ProgressAnimation from "assets/icons/loading.webp"

const StyledTestimonials = styled.section`
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	border-bottom-width: 0;
	color: ${colors.white};
	width: 100%;
	background-color: ${colors.purpleDark};
	margin: 0 auto;
	max-width: 1200px;


  .bullets {
    display: flex;
    align-items: center;
	justify-content: center;
	margin: -4em 0 2.5em 0;
	padding: 0;
	height: 20px;
	align-items: flex-start;

    .bullet {
      width: 13px;
      height: 13px;
      position: relative;
      display: flex;
      margin: 10px 3px 0px; 
      border-radius: 50%;
      transition: all 0.3s;
      cursor: pointer;

	  ${breakpoint.medium`
	  	margin: 0 3px 0px 4px;
	  `}     

      &::before {
        content: "";
        width: 7px;
        height: 7px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        border: 1px solid ${colors.orange};
        border-radius: 50%;
      }

      &:hover,
      &.glide__bullet--active {
        background-color: ${colors.orange};
      }

      &:last-child {
        margin-right: 0;
      }

    }
  }

	.glide__slide {
		margin: 0 auto;
	}
    
`

const StyledTestimony = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 0;
    margin: 0;
    justify-content: flex-start;
	height: fit-content;
	background-color: ${colors.purpleDark};
	max-width: 1300px;
	width: 100%;
	margin: 0 auto;

	${breakpoint.xs`
		flex-direction: row;
		flex-wrap: wrap;
		height: 700px;
	`}  

	${breakpoint.medium`
		height: 750px;
	`}  


  .testimony__content {
		width: 100%;
		position: relative;
		margin: 1rem auto 3rem;
		padding: 20px 35px 20px 20px;
		z-index: 10;
		flex-grow: 1;
		flex-shrink: 1;
		align-self: flex-end;
		width: 100%!important;

		${breakpoint.xs`
			padding: 20px 20px 20px;
			position: absolute;
			max-width: 450px;
		`}     

       ${breakpoint.small`
		max-width: 470px;
		padding: 20px 0 20px 20px;
     	`}     

       ${breakpoint.medium`
		max-width: 550px;
		margin-bottom: 6rem;
		bottom: initial;
		margin-left: 3rem;
		`}  	


	h3 {
		font-size: 35px;
		margin-bottom: 0.5rem;
	}
}

    .testimony {
      position: relative;
      font-size: 16px;
      line-height: 1.4em;
      
		${breakpoint.medium`
		font-size: 17px;
		line-height: 1.5em;
		`}     

		&::before {
			content: "“";
			font-weight: 500;
		}
  
		&::after {
			content: "”";
			font-weight: 500;
		}     
   }


	.author {
		margin-top: 0.6rem;
		font-size: 14px;
		line-height: 1.3em;
		font-style: italic;

	   ${breakpoint.small`
		font-size: 15px;
		line-height: 1.2em;
	  `}  
	}

}

  .testimony__image {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: start-top;
    width: auto;
    position: relative;
    z-index: 10;
    padding: 0px;
	max-width: 700px;
	margin: 0 0 0 -20%;
	width: 100%;
	
	${breakpoint.xs`
		max-width: 800px;
		margin: 0 0 0 auto;
	`}  

	${breakpoint.small`
		align-self: end;
		max-width: 900px;
	`}  

	${breakpoint.medium`
		max-width: 1000px;
	`}  
  }

  .bgimage {
	position: initial;
	background-position: bottom center;
	background-repeat: no-repeat;
	max-width: 1200px;
  }

  .testimonialPict {
	padding: 40px 0 0 0;
	
	${breakpoint.medium`
		padding: 0 0 0 40px;
	`}  


}

  h2 {
	font-size: 20px;
	margin: 0 0 10px 0;
	padding: 0;
	color: ${colors.blue};
	text-transform: uppercase;
}


	${Button} {
	margin: 20px 0 10px;
	width: auto;

	${breakpoint.medium`
	  width: auto;
	`}
  }

`

const StyledModalVideo = styled.div`
  display: none; 
  position: fixed; 
  z-index: 9000; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: hidden; 
  background: rgba(0,0,0,0.8);

  &.active {
		display: flex;
	}


.modal-content {
	position: relative;
	margin: auto;
	width: 100%;
	height: 50%;	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	-webkit-animation-name: animatetop;
	-webkit-animation-duration: 0.4s;
	animation-name: animatetop;
	animation-duration: 0.4s;

  ${breakpoint.xs`
    width: 100%;
    height: 100%;
  `}

}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

.modal-header {
  padding: 2px 16px;
  background-color: ${colors.black}; 
  color: white;
}

.modal-body {padding: 2px 16px;}

.modal-footer {
	padding: 2px 16px;
	background-color: ${colors.black};
	color: white;
}

  iframe {
		width: 100%;
		height: 100%;
		max-width: 900px;
		max-height: 600px;
		background-color: ${colors.black};
		background-image:  url(${ProgressAnimation});
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center;
		
		&:focus {
		  outline: none;
		}		
  }

	.closebox {
		background: ${colors.black};
		max-width: 900px;
		margin: 0px auto;
		display: flex;
		justify-content: end;
		padding:0;
		width: 100%;

		${breakpoint.small`
			padding-bottom: 10px;
			padding-right: 10px;
		`}
	}
	
	.closebtn {
		width: 50px;
		height: 50px;
		opacity: 1;
		cursor: pointer;
		margin-left: 85%;
		margin-top: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 0.01em ${colors.black} dashed;
	}
	
	.closebtn:hover {
	  opacity: 0.3;
	  transition: all 0.3s ease-out 0s;
	}
	.closebtn:before, .closebtn:after {
	  position: absolute;
	  content: ' ';
	  height: 33px;
	  width: 2px;
	  background-color: ${colors.gray};
	}
	.closebtn:before {
	  transform: rotate(45deg);
	}
	.closebtn:after {
	  transform: rotate(-45deg);
	}
	
`

class Carousel extends React.Component {
	constructor(props) {
		super(props)
		this.myRef = React.createRef()
		this.state = {
			showModal: false
		}
	}

	componentDidMount = () => {
		const carousel = new Glide(this.myRef.current, {
			autoplay: 10000,
			animationTimingFunc: 'ease-in',
			type: "carousel"
		})
		carousel.mount()
	}

	stopVideo = (value) => {
		const iframe = document.getElementById(value);
		const origVideo = document.getElementById(value).src;
		if (iframe) {
			iframe.src = "";
			iframe.src = origVideo;
		}
	}

	handleToggleChange = (type, value, event) => {

		const activeFilters = document.querySelectorAll(
			"[data-filter][data-target='" + value + "']"
		)
		activeFilters.forEach(filter => {
			filter.classList.toggle("active")
		})

	}

	openCloseModal = (type, value, event) => {

		const modalWindow = document.querySelectorAll(
			"[data-filter-type='" + type + "'][data-target='" + value + "']"
		)
		modalWindow.forEach(filter => {
			filter.classList.toggle("active")
		})

		if (this.state.showModal) {
			this.setState({
				showModal: false
			},
				() => {
					this.stopVideo(value)
					document.querySelector("html").classList.remove("no-scroll")
					document.querySelector("body").classList.remove("no-scroll")
				}
			)
		} else {
			this.setState({
				showModal: true
			},
				() => {
					document.getElementById(value).src += "?autoplay=1"
					document.querySelector("html").classList.add("no-scroll")
					document.querySelector("body").classList.add("no-scroll")
				}
			)
		}
	}


	render = props => (
		<Testimonials
			myRef={this.myRef}
			openCloseModal={this.openCloseModal}
		/>
	)
}

const Testimonials = props => {
	return (
		<StyledTestimonials data-aos="fade-up" data-aos-once="false">
			<div className="glide" ref={props.myRef}>
				<div className="glide__track" data-glide-el="track">
					<div className="glide__slides">

						<div className="glide__slide">
							<StyledTestimony key="testimonial1" openCloseModal={props.openCloseModal}>
								<div className="testimony__image">
									<StaticImage
										layout="fullWidth"
										src="../../../assets/images/sexual-health-test/doctors/testimonial1.png"
										alt="Dr. Denise L. Sweeney"
										className="bgimage"
										imgClassName="testimonialPict"
									/>
								</div>
								<div className="testimony__content">
									<h2>what are people saying</h2>
									<h3>The Visby Sexual Health Test has been a game changer for us.</h3>
									<p className="testimony">My patients come in, self-collect and get answers in the same visit. I appreciate that it facilitates education,
										I can provide specific education and treatment in the same visit - this really helps reduce patient anxiety. We've simplified our
										workflow with reduced need to chase down patients for results and pharmacy preferences. I’m also spending less time reviewing lab results in my inbox too.
										<br />Our office and patients are very satisfied with the Visby solution.
									</p>
									<Button backgroundcolor="primary"
										onClick={() => props.openCloseModal("video", "dr-sweeney")}
									>Watch Dr. Sweeney's video</Button>
									<p className="author">Denise L. Sweeney, MD FACOG, Associates in Women’s Healthcare, Roseville, CA</p>
								</div>
							</StyledTestimony>
						</div>

						<div className="glide__slide">
							<StyledTestimony key="testimonial2">
								<div className="testimony__image">
									<StaticImage
										layout="fullWidth"
										src="../../../assets/images/sexual-health-test/doctors/testimonial2.png"
										alt="Dr. Lamont Tyler"
										className="bgimage"
										imgClassName="testimonialPict"
									/>
								</div>
								<div className="testimony__content">
									<h2>what are people saying</h2>
									<h3>Visby innovation has become essential for maintaining quality urgent care and positive patient experience.</h3>
									<p className="testimony">Fast and accurate results mean we can usually keep our ‘door to discharge time' under one hour -- patients
										have a convenient, complete engagement and we are able to provide appropriate care without the risk of unnecessary antibiotics
										that can happen when a definitive diagnosis is delayed. The Visby test is a win-win, fighting antibiotic resistance as well as
										the spread of untreated infection, while empowering patients to engage health services with greater confidence.</p>
									<p className="author">Lamont Tyler, MD, Market Medical Director of CareNow Urgent Care, Henderson, NV</p>
								</div>
							</StyledTestimony>
						</div>

						<div className="glide__slide">
							<StyledTestimony key="testimonial2">
								<div className="testimony__image">
									<StaticImage
										layout="fullWidth"
										src="../../../assets/images/sexual-health-test/doctors/testimonial3.png"
										alt="Jessica Scharfenberg"
										className="bgimage"
										imgClassName="testimonialPict"
									/>
								</div>
								<div className="testimony__content">
									<h2>what are people saying</h2>
									<h3>The main benefit of using the Visby tests is having a same-day immediate results.</h3>
									<p className="testimony">A lot of clinics you can get same-day results but those are going to come later in the day.
										We can actually give clients the results before they leave as we’re getting results within 30 minutes. We can start the
										test at the beginning of the appointment and by the time they’re done with their provider in the clinic assistance we’re
										going to have those labs so we can give them that same day but we can also provide treatment to that day as well as
										many individuals in the healthcare field know doing that follow-up calls for individuals who are positive can be very difficult.
									</p>
									<Button backgroundcolor="primary"
										onClick={() => props.openCloseModal("video", "scharfenberg")}
									>Watch Jessica Scharfenberg's video</Button>
									<p className="author">Jessica Scharfenberg, CEO of HealthFirst Network, Wausau,&nbsp;WI</p>
								</div>
							</StyledTestimony>
						</div>
					</div>
					<div className="bullets glide__bullets" data-glide-el="controls[nav]">
						<button type="bullet" className="bullet" data-glide-dir="=0" aria-label="Dr. Denise L. Sweeney"></button>
						<button type="bullet" className="bullet" data-glide-dir="=1" aria-label="Dr. Lamont Tyler"></button>
						<button type="bullet" className="bullet" data-glide-dir="=2" aria-label="Jessica Scharfenberg"></button>
					</div>
				</div>
			</div>

			<StyledModalVideo
				data-filter
				data-filter-type="video"
				data-target="dr-sweeney"
				openCloseModal={props.openCloseModal}
				key="dr-sweeney"
			>
				<div className="modal-content">
					<div className="closebox">
						<button className="closebtn"
							aria-label="close box"
							onClick={() => props.openCloseModal("video", "dr-sweeney")}
							onKeyDown={() => props.openCloseModal("video", "dr-sweeney")}
						></button>
					</div>
					<iframe
						id="dr-sweeney"
						title="Watch Dr. Sweeney's testimonial"
						src="https://www.youtube.com/embed/x9F-4u8i4rw"
						webkitallowfullscreen="true"
						mozallowfullscreen="true"
						loading="lazy"
					></iframe>
				</div>
			</StyledModalVideo>

			<StyledModalVideo
				data-filter
				data-filter-type="video"
				data-target="scharfenberg"
				openCloseModal={props.openCloseModal}
				key="scharfenberg"
			>
				<div className="modal-content">
					<div className="closebox">
						<button className="closebtn"
							aria-label="close box"
							onClick={() => props.openCloseModal("video", "scharfenberg")}
							onKeyDown={() => props.openCloseModal("video", "scharfenberg")}
						></button>
					</div>
					<iframe
						id="scharfenberg"
						title="Watch Jessica Scharfenberg's"
						src="https://www.youtube.com/embed/QyvYPu-a1gs"
						webkitallowfullscreen="true"
						mozallowfullscreen="true"
						loading="lazy"
					></iframe>
				</div>
			</StyledModalVideo>




		</StyledTestimonials>
	)
}

export default Carousel
